import { AnchorHTMLAttributes, createElement } from 'react';
import Image from 'next-export-optimize-images/image';

import CTAButton from '~/components/shared/CTAButton';
import LinkWrapper from '~/components/shared/LinkWrapper/linkWrapper';

/**
 * handleSuperscripts
 * Uses a regex to parse out superscripts and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} supClassname - Optional classname to apply styling to superscripts
 * @param {RegExp} regex - Optional regex to pass if desired regex to match is different than default (i.e. /(_\w+_)/g ->  matches all letters + numbers)
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * handleSuperscripts('As soon as today, at the low prices you love._1_');
 */
export const handleSuperscripts = (
  text: string,
  supClassName?: string,
  regex?: RegExp
) => {
  const finalRegex = regex ? regex : /(_\d+_)/;
  const textArray = text.split(finalRegex);
  return textArray.map((str, idx) => {
    if (finalRegex.test(str)) {
      const noUnderscoreStr = str.replace(/_/g, '');
      return (
        <sup key={`${str}${idx.toString()}`} className={supClassName || ''}>
          {noUnderscoreStr}
        </sup>
      );
    }
    return str;
  });
};

/**
 * handleLinks
 * Uses a regex to parse out links and superscripts `and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {RegExp} regex - Desired regex to match on based on link content (i.e. /(_LINK_[^*]*_LINK_)/)
 * @param {string} href - link
 * @param {string} linkClassname - Optional classname to apply styling to link
 * @param {string} tabIndex - Optional tabindex to add if needed for accessibility purposes
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * handleLinks('Must be present for pickup. _LINK_Restrictions apply._LINK_', /(_LINK_[^*]*_LINK_)/, disclaimerLink || '', styles.disclaimerLink, -1));
 */
export const handleLinks = (
  text: string,
  regex = /(_LINK_[^*]*_LINK_)/,
  href: string,
  linkClassName?: string,
  tabIndex?: number,
  superscriptClassName?: string
) => {
  const textArray = text.split(regex);
  return textArray.map((str) => {
    if (regex.test(str)) {
      const noUnderscoreStr = str.replaceAll('_LINK_', '');
      return (
        <LinkWrapper
          href={href}
          target='_blank'
          key={str}
          tabIndex={tabIndex || 0}
          className={linkClassName || ''}
          rel='noreferrer noopener'
          segmentTracking={false}
        >
          {noUnderscoreStr}
        </LinkWrapper>
      );
    }
    // regex checks for numbers or asteriscks blocked by underscores
    return handleSuperscripts(str, superscriptClassName, /(_\d+_)|(_\*+_)/);
  });
};

/**
 * handleMultipleLinks
 * Uses a regex to parse out multiple links and superscripts and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string[]} hrefs - An array of links
 * @param {string} linkClassName - Optional classname to apply styling to link
 * @param {number} tabIndex - Optional tabindex to add if needed for accessibility purposes
 * @param {string} superscriptClassName - Optional classname to apply styling to superscripts
 */
export const handleMultipleLinks = (
  text: string,
  hrefs: string[],
  linkClassName?: string,
  tabIndex?: number,
  superscriptClassName?: string
) => {
  const regex = /(_LINK_[^*].*?_LINK_)/g;
  const textArray = text.split(regex);
  let idx = -1;

  return textArray.map((str) => {
    if (regex.test(str)) {
      idx++;
      const noUnderscoreStr = str.replaceAll('_LINK_', '');
      return (
        <LinkWrapper
          href={hrefs[idx]}
          target='_blank'
          key={str}
          tabIndex={tabIndex || 0}
          className={linkClassName || ''}
          rel='noreferrer noopener'
          segmentTracking={false}
        >
          {noUnderscoreStr}
        </LinkWrapper>
      );
    }
    // regex checks for numbers or asteriscks blocked by underscores
    return handleSuperscripts(str, superscriptClassName, /(_\d+_)|(_\*+_)/);
  });
};

// TODO: DEPRECATE IN FAVOR OF handleLinks or handleMultipleLinks
/**
 * parseLinks
 * Uses a regex to parse out links and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} copy - A string of editable content
 * @param {string} elementPosition - The section where these links appear
 * @param {Omit<LinkHTMLAttributes<HTMLLinkElement>>} [linkProps] - Optional props to pass directly to the rendered `a` element
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * parseLinks('You can cancel from your _LINK_Walmart+ account page_https://www.walmart.com/grocery/account/plus/overview_ or _LINK_contact customer care_https://walmart.com/help_.', 'FAQs');
 */
export const parseLinks = (
  copy: string,
  elementPosition: string,
  linkProps?: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>
): (JSX.Element | JSX.Element[])[] =>
  copy.split(/_LINK_/g).map((str) => {
    const matches = Array.from(str.matchAll(/([^_]+)_(https?:\/\/[^_]+)_/g));

    if (matches.length === 0 || !matches[0]) {
      return <div key={str}>{str}</div>;
    }

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    return matches.map(([_, linkText, href], i) => (
      <div key={matches[i].join('')}>
        <CTAButton
          variant='text-link'
          href={href}
          elementPosition={elementPosition}
          {...linkProps}
        >
          {linkText}
        </CTAButton>
        {str.replace(matches[i][0], '')}
      </div>
    ));
  });

/**
 * yellowPlus
 * Returns a yellow plus React Element with optional custom styling
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} imgPath - A path where the image lives
 * @param {string} linkClassname - Optional classname to apply styling to image
 * @returns {JSX.Element}
 *
 * @example
 * yellowPlus(`${process.env.baseUrl}/images/sections/hero/carousel/`, styles.yellowPlus);
 */
export const yellowPlus = (imgPath: string, className?: string) => (
  <Image
    src={`${imgPath}yellow-plus.svg`}
    className={className}
    alt='yellow plus sign'
    height={38}
    width={38}
    loading='eager' // Adding in eager here since this is being used by the Carousel Hero
  />
);

/**
 * handlePlus
 * Uses a regex to parse out `+` text into an SVG and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} imgPath - The image path of the svg
 * @param {string} className - Optional styling to apply to the svg
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * handlePlus('+so much more', `${process.env.baseUrl}/images/sections/hero/carousel/`, styles.yellowPlus);
 */

export const handlePlus = (
  text: string,
  imgPath: string,
  plusClassName?: string,
  textToReplaceWithSVG = '_PLUS_',
  textClassName?: string
) => {
  const plusArr = text.split(textToReplaceWithSVG);
  const parsedPlusArr = plusArr.map((str, idx) => {
    if (str === '') {
      return yellowPlus(imgPath, plusClassName || 'yellow-plus');
    }
    if (str.match(/(_\d+_)/g)) {
      return handleSuperscripts(str);
    }
    return (
      <p key={`${str}${idx.toString()}`} className={textClassName}>
        {str}
      </p>
    );
  });

  return parsedPlusArr;
};

/**
 * parseTextWithSVG
 * Uses a regex to parse out text into an SVG and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} svgs - Key value pair of SVGs to replace corresponding text with
 * @param {string} superscriptClassName - Optional styling to apply to the superscript
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * parseTextWithSVG('It's the _SVG_mother_SVG_ of all savings.', {mother: <MotherStamp />}`, styles.superscript);
 */

export const parseTextWithSVG = (
  text: string,
  svgs: { [key: string]: JSX.Element },
  superscriptClassName?: string
) => {
  if (text.includes('_SVG_')) {
    const svgArr = text.split(/_SVG_(.*?)_SVG_/g);
    const parsedSVGArr = svgArr.map((str) => {
      if (svgs[str as keyof typeof svgs]) {
        return <span key={str}>{svgs[str as keyof typeof svgs]}</span>;
      }
      if (str.match(/(_\d+_)/g)) {
        return handleSuperscripts(str, superscriptClassName);
      }
      return str;
    });
    return parsedSVGArr;
  }

  return handleSuperscripts(text, superscriptClassName);
};

/**
 * parseHeadlineWithSVG
 * Uses a regex to parse out a headline that could contain an SVG (+), superscripts, and/or linebreaks
 * and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} imgPath - The image path of the svg
 * @param {string} textClassName - Optional styling to apply to the text
 * @param {string} svgClassName - Optional styling to apply to the svg
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * parseHeadlineWithSVG('save as_BREAK_a member_BREAK_+so much more', `${process.env.baseUrl}/images/sections/hero/carousel/`, '+', handlePlus, styles.headline, styles.yellowPlus);
 */
export const parseHeadlineWithSVG = (
  text: string,
  imgPath: string,
  textToReplaceWithSVG: string,
  parseSVGFn: Function,
  textClassName?: string,
  svgClassName?: string,
  superscriptClassName?: string
) => {
  if (text.includes('_BREAK_') && text.match(/(_\d+_)/g)) {
    const textArray = text.split('_BREAK_');
    const parsedText = textArray.map((str, idx) => {
      if (str.includes(textToReplaceWithSVG)) {
        return (
          <p key={`${str}${idx.toString()}`} className={textClassName}>
            {parseSVGFn(
              str,
              imgPath,
              svgClassName,
              textToReplaceWithSVG,
              textClassName
            )}
          </p>
        );
      }
      if (str.match(/(_\d+_)/g)) {
        return (
          <div key={`${str}${idx.toString()}`} className={textClassName}>
            {handleSuperscripts(str, superscriptClassName)}
          </div>
        );
      }
      return (
        <p key={`${str}${idx.toString()}`} className={textClassName}>
          {str}
        </p>
      );
    });
    return parsedText;
  }
  if (text.includes('_BREAK_')) {
    const textArray = text.split('_BREAK_');
    const parsedText = textArray.map((str, idx) => {
      if (str.includes(textToReplaceWithSVG)) {
        return (
          <div key={`${str}${idx.toString()}`} className={textClassName}>
            {parseSVGFn(
              str,
              imgPath,
              svgClassName,
              textToReplaceWithSVG,
              textClassName
            )}
          </div>
        );
      }
      return (
        <p key={`${str}${idx.toString()}`} className={textClassName}>
          {str}
        </p>
      );
    });

    return parsedText;
  }
  return (
    <p key={text} className={textClassName}>
      {text}
    </p>
  );
};

/**
 * handleStrike
 * Uses a regex to parse out a headline that could contain a strikethrough or superscripts
 * and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} strikeClassName - Styling to apply to strikethrough text
 * @param {string} superscriptClassName - Optional styling to apply to the superscript
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * handleStrike('_STRIKE_$98_STRIKE_ $49 for a year. it's like 6 months free.', styles.strike, styles.super);
 */

export const handleStrike = (
  text: string,
  strikeClassName: string,
  superscriptClassName?: string
) => {
  const strikeRegex = /(_STRIKE_.*_STRIKE_)/g;
  const superscriptRegex = /(_\d+_)/g;
  const textArray = text.split(strikeRegex);
  return textArray.map((str) => {
    if (strikeRegex.test(str)) {
      const strikeText = str.replaceAll('_STRIKE_', '');
      return (
        <span key={strikeText} className={strikeClassName}>
          {strikeText}
        </span>
      );
    }
    if (superscriptRegex.test(str)) {
      return handleSuperscripts(str, superscriptClassName || '');
    }

    return str;
  });
};

/**
 * parseHeadlineWithStrike
 * Uses a regex to parse out a headline that could contain a strikethrough, superscripts, and/or linebreaks
 * and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} strikeClassName - Styling to apply to strikethrough text
 * @param {string} textClassName - Styling to apply to the text
 * @param {string} superscriptClassName - Optional styling to apply to the superscript
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * parseHeadlineWithStrike('_STRIKE_$98_STRIKE_ $49 for a year._BREAK_it's like 6 months free.', styles.strike, styles.headline, styles.super);
 */

export const parseHeadlineWithStrike = (
  text: string,
  strikeClassName: string,
  textClassName: string,
  superscriptClassName?: string
) => {
  if (text.includes('_BREAK_')) {
    const textArray = text.split('_BREAK_');
    return textArray.map((str, idx) => {
      if (str.includes('_STRIKE_')) {
        return handleStrike(str, strikeClassName, superscriptClassName);
      }

      if (str.match(/(_\d+_)/g)) {
        return (
          <p key={`${str}${idx.toString()}`} className={textClassName}>
            {handleSuperscripts(str, superscriptClassName)}
          </p>
        );
      }

      return (
        <p key={`${str}${idx.toString()}`} className={textClassName}>
          {str}
        </p>
      );
    });
  }
  if (text.includes('_STRIKE_')) {
    return handleStrike(text, strikeClassName, superscriptClassName);
  }

  return text;
};

/**
 * handleBreaks
 * Uses a regex to parse out a string that could contain superscripts, and/or linebreaks
 * and returns an array of React Elements
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} type - Defines what type of text tag to return
 * @param {string} textClassName - Optional styling to apply to the text
 * @param {string} superscriptClassName - Optional styling to apply to the superscript
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * handleBreaks('Save with_BREAK_grocery delivery', 'h3', styles.headline, styles.super);
 */

export const handleBreaks = <T extends keyof HTMLElementTagNameMap>(
  text: string,
  type: T,
  textClassName?: string,
  superscriptClassName?: string
) => {
  if (text.includes('_BREAK_')) {
    const textArray = text.split('_BREAK_');
    return textArray.map((str, idx) => {
      if (str.match(/(_\d+_)/g)) {
        return createElement(
          type,
          { key: `${str}-${idx}`, className: textClassName },
          handleSuperscripts(str, superscriptClassName)
        );
      } else {
        return createElement(
          type,
          { key: `${str}-${idx}`, className: textClassName },
          str
        );
      }
    });
  }
  return [
    createElement(
      type,
      { className: textClassName },
      handleSuperscripts(text, superscriptClassName)
    ),
  ];
};

/**
 * parseColor
 * Uses a regex to parse out a headline that could contain another color
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} colorClassName - Styling to apply to the colored text
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * parseColor('HOLIDAY _COLOR_DEALS_COLOR_', styles.yellowText);
 */

export const parseColor = (text: string, colorClassName: string) => {
  const colorRegex = /(_COLOR_.*?_COLOR_)/g;
  if (text.includes('_COLOR_')) {
    const textArray = text.split(colorRegex);
    return textArray.map((str, idx) => {
      if (colorRegex.test(str)) {
        str = str.replaceAll('_COLOR_', '');
        return (
          <span key={`${str}-${idx}`} className={colorClassName}>
            {str}
          </span>
        );
      }
      return str;
    });
  }
  return text;
};

/**
 * parseBold
 * Uses a regex to parse out text that contains bolded text and/or superscripts
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string of editable content
 * @param {string} boldClassName - Styling to apply to the bolded text
 * @param {string} superscriptClassName - Styling to apply to the superscript if there is one
 * @returns {Array<JSX.Element | Array<JSX.Element>>}
 *
 * @example
 * parseBold('HOLIDAY _BOLD_DEALS_BOLD_', styles.bold, styles.superscript);
 */

export const parseBold = (
  text: string,
  boldClassName: string,
  superscriptClassName?: string
) => {
  const boldRegex = /(_BOLD_.*?_BOLD_)/;
  if (text.includes('_BOLD_')) {
    const textArray = text.split(boldRegex);
    return textArray.map((str, idx) => {
      if (boldRegex.test(str)) {
        str = str.replaceAll('_BOLD_', '');
        return (
          <strong key={`${str}-${idx}`} className={boldClassName}>
            {handleSuperscripts(str, superscriptClassName)}
          </strong>
        );
      }
      return handleSuperscripts(str, superscriptClassName);
    });
  }

  return handleSuperscripts(text, superscriptClassName);
};

/**
 * isEmpty
 * Checks if a string is equal to '_EMPTY_'
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string to check the contents of
 *
 * @example
 * isEmpty(subheadline);
 */
export const isEmpty = (str: string) => {
  return str === '_EMPTY_';
};

/**
 * toCamelCase
 * Converts a string to camelCase to map to the Monarch slotId
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string to convert to camelCase
 *
 * @example
 * toCamelCase('SECTION_01');
 */
export const toCamelCase = (str: string) => {
  let substring = str.split('_');
  substring = substring.map((str) => (str === 'CTA' ? str : str.toLowerCase()));
  const camelCaseString =
    substring[0] +
    substring
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  return camelCaseString;
};

/**
 * parseCamelCase
 * Converts a string to uppercase snake_case omitting the word 'Editable'
 * @category Helper
 * @subcategory Editable Helpers
 *
 * @func
 * @param {string} text - A string to convert to snake_case
 *
 * @example
 * parseCamelCase('editableCTABanner');
 */
export const convertCamelCaseToSnakeCase = (str: string | undefined) => {
  if (!str) return;

  const snakeCaseStr = str.match(/[A-Z][a-z]*|[0-9]+/g)?.join('_');
  const parsedStr = snakeCaseStr
    ?.toUpperCase()
    .replaceAll('EDITABLE_', '')
    .replaceAll('C_T_A', 'CTA');
  return parsedStr;
};
